<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center" class="pa-0">
        <v-card
          max-width="600px"
          outlined
          :flat="$vuetify.breakpoint.smAndDown"
        >
          <v-form ref="form">
            <v-card-text>
              <v-container class="pb-0">
                <v-row>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="7"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      autofocus
                      :label="$t('admin.account.companyNameLabel')"
                      :disabled="loading"
                      v-model="user.companyName"
                      :rules="inputRules"
                    >
                      <v-icon slot="prepend" color="primary lighten-1"
                        >mdi-domain</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="5"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.phoneLabel')"
                      :disabled="loading"
                      v-model="user.phone"
                      prepend-icon="mdi-empty"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="6"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.streetLabel')"
                      :disabled="loading"
                      v-model="user.street"
                      prepend-icon="mdi-empty"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="6"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.cityLabel')"
                      :disabled="loading"
                      v-model="user.city"
                      prepend-icon="mdi-empty"
                      :rules="inputRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="4"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.stateLabel')"
                      :disabled="loading"
                      v-model="user.state"
                      prepend-icon="mdi-empty"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="4"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.postalCodeLabel')"
                      :disabled="loading"
                      v-model="user.postalCode"
                      prepend-icon="mdi-empty"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="4"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-autocomplete
                      :label="$t('admin.account.countryLabel')"
                      :disabled="loading"
                      v-model="user.country"
                      prepend-icon="mdi-empty"
                      :items="countries"
                      item-text="countryName"
                      item-value="code"
                      @change="countryChanged"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="5"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.firstNameLabel')"
                      :disabled="loading"
                      v-model="user.firstName"
                    >
                      <v-icon slot="prepend" color="primary lighten-1"
                        >mdi-account</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    sm="7"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.lastNameLabel')"
                      :disabled="loading"
                      v-model="user.lastName"
                      prepend-icon="mdi-empty"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    class="pa-0 px-3"
                    sm="7"
                    :class="{ 'hide-empty': $vuetify.breakpoint.smAndDown }"
                  ></v-col>
                  <v-col
                    class="pa-0 px-3"
                    cols="12"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.emailLabel')"
                      :disabled="true"
                      v-model="user.email"
                      prepend-icon="mdi-email"
                    >
                      <v-icon slot="prepend" color="primary lighten-1"
                        >mdi-email</v-icon
                      >
                    </v-text-field>
                  </v-col>
                  <v-col class="pa-0 px-3" cols="12" sm="5"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.DistributorfirstNameLabel')"
                      :disabled="loading"
                      v-model="user.DistributorfirstName">
                      <v-icon slot="prepend" color="primary lighten-1">mdi-account</v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col class="pa-0 px-3" cols="12" sm="7"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.DistributorlastNameLabel')"
                      :disabled="loading"
                      v-model="user.DistributorlastName"
                      prepend-icon="mdi-empty"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 px-3" cols="12"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.DistributorEmailLabel')"
                      :disabled="loading"
                      v-model="user.DistributorEmail"
                      prepend-icon="mdi-email"
                    >
                      <v-icon slot="prepend" color="primary lighten-1">mdi-email</v-icon>
                    </v-text-field>
                  </v-col>


                  <v-col class="pa-0 px-3" cols="12" sm="5"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.LENOXcontactfirstNameLabel')"
                      :disabled="loading"
                      v-model="user.LENOXcontactfirstName">
                      <v-icon slot="prepend" color="primary lighten-1">mdi-account</v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col class="pa-0 px-3" cols="12" sm="7"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.LENOXcontactlastNameLabel')"
                      :disabled="loading"
                      v-model="user.LENOXcontactlastName"
                      prepend-icon="mdi-empty"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-0 px-3" cols="12"
                    :class="{ 'padding-input': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-text-field
                      :label="$t('admin.account.LENOXcontactEmailLabel')"
                      :disabled="loading"
                      v-model="user.LENOXcontactEmail"
                      prepend-icon="mdi-email"
                    >
                      <v-icon slot="prepend" color="primary lighten-1">mdi-email</v-icon>
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-checkbox
                      v-model="user.marketingEmail"
                      :label="$t('admin.account.marketingEmailLabel')"
                      :disabled="loading"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions
              :class="{
                'padding-action-small': $vuetify.breakpoint.smAndDown,
                'padding-action': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <v-container class="px-1 py-0">
                <v-row>
                  <v-col class="pt-0">
                    <v-btn
                      depressed
                      tile
                      v-html="$t('admin.account.updateButton')"
                      class="primary white--text full-width"
                      :disabled="loading"
                      :loading="loading"
                      @click="update"
                    ></v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { MASK } from "@/constants/Masks";

export default {
  name: "UserDetails",

  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  directives: {
    mask,
  },

  data() {
    return {
      country: { code: "", companyName: "" },
      inputRules: [
        (v) => (!!v && v.length > 0) || this.$t("admin.account.inputRules"),
      ],
      phoneMask: MASK.phone,
      loading: false,
    };
  },

  computed: {
    ...mapGetters([
      "registerDialog",
      "countries",
      "defaultCountry",
      "axiosSource",
    ]),
  },

  methods: {
    countryChanged(id) {
      let country = this.countries.find(({ code }) => code === id);

      if (!country) {
        country = this.defaultCountry;
      }

      this.user.country = country.code;
    },
    update() {
      if (this.$refs.form.validate()) {
        const form = {
          userID: this.user.userID,
          companyName: this.user.companyName,
          street: this.user.street,
          city: this.user.city,
          state: this.user.state,
          postalCode: this.user.postalCode,
          country: this.user.country,
          phone: this.user.phone,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          marketingEmail: this.user.marketingEmail,
          DistributorfirstName: this.user.DistributorfirstName,
          DistributorlastName: this.user.DistributorlastName,
          DistributorEmail: this.user.DistributorEmail,
          LENOXcontactEmail: this.user.LENOXcontactEmail,
          LENOXcontactfirstName: this.user.LENOXcontactfirstName,
          LENOXcontactlastName: this.user.LENOXcontactlastName
        };

        this.loading = true;

        this.$store.dispatch("updateAccount", form).then(
          (response) => {
            this.loading = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("error: ", error);
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}

.hide-empty {
  display: none;
}
</style>

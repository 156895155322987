<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-row>
          <v-col>
            <UserDetails :user="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import UserDetails from "@/components/common/UserDetails";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "Account",

  components: {
    UserDetails,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["user"]),
    item() {
      if (this.user) {
        return this.user;
      } else {
        return {};
      }
    },
  },

  methods: {
    getUser() {
      this.loading = true;
      this.$store.dispatch("getUser").then(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
  },

  mounted() {
    this.getUser();
  },
};
</script>
